import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { RouterLink, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator, MatPaginatorModule, MatPaginatorIntl} from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RacedataService } from './racedata.service';
import {Racer, Driver, Lap, LapFlag, PersistHelper, FCYellow} from '../raceproc/raceproc'
import { DatePipe } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { trigger, sequence, state, animate, transition, style, keyframes } from '@angular/animations';

import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
@Injectable()
export class MatPaginatorCustom extends MatPaginatorIntl {
    itemsPerPageLabel = "Laps:";
}

export interface PromptDialogData {
    title: string;
    prompt: string;
    value: string;
  }

  export interface PromptPickDialogData {
    title: string;
    prompt: string;
    options: Array<[string, string]>;
    value: string;
  }
  

@Component({
    selector: 'prompt-dialog',
    templateUrl: 'prompt.component.html',
  })
  export class PromptDialog {
  
    constructor(
      public dialogRef: MatDialogRef<PromptDialog>,
      @Inject(MAT_DIALOG_DATA) public data: PromptDialogData) 
      {
      }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }

  @Component({
    selector: 'promptpick-dialog',
    templateUrl: 'promptpick.component.html',
    styleUrls: ['promptpick.component.css']
  })
  export class PromptPickDialog {
  
    constructor(
      public dialogRef: MatDialogRef<PromptPickDialog>,
      @Inject(MAT_DIALOG_DATA) public data: PromptPickDialogData) 
      {
      }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }
  
  @Injectable()
 export class StorageServicePersistHelper  extends PersistHelper {
     constructor(@Inject(LOCAL_STORAGE) private storage: StorageService)
     {
        super();
     }
     set(key: string, value: any) : void
     {
         this.storage.set(key, value);
     }
    get(key: string) : any | undefined
    {
        return this.storage.get(key);
    }

}

/*
export const rowsAnimation = 
    trigger('rowsAnimation', [
      transition('* => *', [
        style({ height: '*', opacity: '0', transform: 'translateX(-550px)', 'box-shadow': 'none' }),
        sequence([
          animate(".35s ease", style({ height: '*', opacity: '.2', transform: 'translateX(0)', 'box-shadow': 'none'  })),
          animate(".35s ease", style({ height: '*', opacity: 1, transform: 'translateX(0)' }))
        ])
      ])
    ]);
*/
/*
export const rowsAnimation = 
    trigger('rowsAnimation', [
      transition('* => *', [
        sequence([
            animate('.35s',  style({ backgroundColor: '#ff9999' })),
            animate('.35s', style({ backgroundColor: '*' }))
          ])        
        ])
]);
*/

function myInlineMatcherFn(fromState: string, toState: string, element: any, params: {[key:
    string]: any}): boolean {
       //hack: When the list is re-ordered (a racer changes position) 
       //the row is marked as "setForMove" which disables animations, so it doesn't highlight
       //this seems to work-around that issue
       if ('__ng_removed' in element)
        element["__ng_removed"]["setForMove"] = false;
      // console.log(`${fromState} ${toState}`, element, params);
       return true;
       
    }
    /*':increment'*/
    
export const rowsAnimation = 
    trigger('rowsAnimation', [
      transition(myInlineMatcherFn, [
        sequence([
            animate('.35s',  style({ backgroundColor: '#ffcccc' })),
            animate('.35s', style({ backgroundColor: '*' }))
          ])        
        ])          
]);
@Injectable()
@Component({
    selector: 'app-mygrid',
    templateUrl: './mygrid.component.html',
    styleUrls: ['./mygrid.component.css'],
    animations: [rowsAnimation],
    providers: [DatePipe, {provide: MatPaginatorIntl, useClass: MatPaginatorCustom}]
})
export class MygridComponent implements OnInit {
    classList: string[] = ['All'];
    selectedClass: string = 'All';
    pitStrategyView: boolean = false;
    get showAllClasses() : boolean {return this.selectedClass == 'All';}
    clickMessage = '';
  
    @ViewChild(MatPaginator, {static: true}) lapPaginator: MatPaginator;
    
    constructor(private raceData: RacedataService, public datePipe: DatePipe, public dialog: MatDialog, private http: HttpClient, private route: ActivatedRoute) 
    {         

    }
    getParameterByName(name: any) {
        let url = window.location.href;
        name = name.replace(/[[]]/g, "\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace("/+/g", " "));
    }
    ngOnInit() {
        // setInterval(() => { ELEMENT_DATA[3].weight = Math.random(); }, 5000)
        
        //let liveUrl = this.route.snapshot.queryParamMap.get('live');
        let liveUrl = this.getParameterByName('live');
        if (liveUrl)
            this.raceData.LoadLiveData(liveUrl, (err: string) => 
            { 
                if (err)
                    this.clickMessage = err;
                this.RefreshDisplay();
            }, () => {this.RefreshDisplay(false)});
        else
            this.raceData.LoadStaticData( (err: string) => 
            { 
                if (err)
                    this.clickMessage = err;
                this.RefreshDisplay();
            }, () => {this.RefreshDisplay(false)});

        this.lapData.paginator = this.lapPaginator;
        //this.driverData.filter = "X";
        //this.driverData.filterPredicate = (driver: Driver, filter: string) => {return driver.firstLap != 0;};
        this.selection.changed.subscribe(() => {
            //console.log("selection changed");
            this.driverData.data = this.selectedRacerDrivers;
            this.lapData.data = this.selectedRacerLaps;
            this.pitData.data = this.selectedRacerPits;
            this.lapData.paginator = this.lapPaginator;

        });
    }
    private get selectedRacerDrivers() : Array<Driver>
    {
        if (!this.selectedRacer)
            return new Array<Driver>();
        return this.selectedRacer.drivers.slice(1).sort((a: Driver, b: Driver) => {return b.firstLap - a.firstLap;});
    }

    private get selectedRacerLaps() : Array<Lap>
    {
        if (!this.selectedRacer)
            return  new Array<Lap>();
        return this.selectedRacer.laps.slice(1).sort((a: Lap, b: Lap) => {return b.num - a.num;});
    }
    private get selectedRacerPits() : Array<Lap>
    {
        if (!this.selectedRacer)
            return new Array<Lap>();;
        return this.selectedRacer.laps.filter((lap: Lap) => {return lap.isPitStop;}).sort((a: Lap, b: Lap) => {return b.num - a.num;});
    }

    onClassChanged(event)
    {
        //console.log(this.showAllClasses);
        //this.RefreshDisplay();
        this.onViewChanged(event);
    }
    onViewChanged(event)
    {
        this.raceData.pitAdjusted = this.pitStrategyView;
        this.mainTableColumns = (this.pitStrategyView) ?
            (this.showAllClasses) ? this.mainTablePitStrategyViewAll : this.mainTablePitStrategyViewClass :
            (this.showAllClasses) ? this.mainTableStandardViewAll : this.mainTableStandardViewClass;
        this.RefreshDisplay();
    }

    onNextPassing(num: number) {
        this.raceData.NextPassing(num);
        this.RefreshDisplay(false);     
    }
    onClickMe() {
        //this.clickMessage += 'You are my hero! ';
        this.editPitInterval(null);
        //ELEMENT_DATA[3].weight = Math.random();
        /*
        this.raceData.getTextFile().subscribe((data: string) => {
          this.clickMessage ='Got it' ;
        });*/
        //this.raceData.LoadData();
    }

    get selectedRacer() : Racer
    {
        if (this.selection.selected.length)
            return this.selection.selected[0];
        return null;
    }

    checkboxLabel(row?: Racer): string {
        if (!row) {
            return '';//return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        
        //this.clickMessage = row.positionInClass ? row.positionInClass.toString() : "unknown";
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.positionInClass + 1}`;
    }

    RefreshDisplay(recalculatePostions: boolean = true)
    {
        if (recalculatePostions)
            this.raceData.RecalculateRelativePositions();
        this.classList = ['All'].concat(this.raceData.GetClassList().sort());
        this.mainTable.data = this.raceData.GetRacers((this.selectedClass == 'All') ? "" : this.selectedClass);
        this.driverData.data = this.selectedRacerDrivers;
        this.lapData.data = this.selectedRacerLaps;
        this.pitData.data = this.selectedRacerPits;   
        this.fcYellowData.data = this.raceData.GetFCYellows();
        this.mainTableColumns = this.mainTableColumns.slice();
    }

    editRaceDuration()
    {
        
        const dialogRef = this.dialog.open(PromptDialog, {
            width: '400px',
            data: {title: 'Modify Race Duration', prompt: 'Race Duration (minutes):',  value: this.raceData.GetTimeCheck().totalDuration / 60}
          });
      
          dialogRef.afterClosed().subscribe(result => {            
            if (result)
            {
                this.raceData.SetDuration(Number.parseInt(result) * 60);
                this.RefreshDisplay();                
            } 
            
          });
    }

    editDriverName(driver: Driver)
    {
        
        const dialogRef = this.dialog.open(PromptDialog, {
            width: '400px',
            data: {title: 'Modify Driver Name', prompt: 'Driver Name:',  value: driver.name}
          });
      
          dialogRef.afterClosed().subscribe(result => {            
            if (result)
            {
                driver.name = result;
                this.RefreshDisplay();                
            } 
            
          });
    }

    
    editLapType(lap: Lap)
    {
        
        let lapOptions = new Array<[string, string]>();
        for (let i = 0 ; i < LapFlag.LAST_LAP_FLAG ; i++)
            lapOptions.push([LapFlag[i], LapFlag[i]]);
        const dialogRef = this.dialog.open(PromptPickDialog, {
            width: '400px',
            data: {title: 'Modify Lap Type', prompt: 'Lap Type:',  value: LapFlag[lap.lapFlag], options: lapOptions}
          });
      
          dialogRef.afterClosed().subscribe(result => {            
            if (result)
            {
                this.selectedRacer.UserChangeLapType(lap, LapFlag[<string>(result)]);
                //lap.lapFlag =  LapFlag[<string>(result)];
              //  driver.name = result;
                this.RefreshDisplay();                
            } 
            
          });
    }

    editLapTime(lap: Lap)
    {
        
        const dialogRef = this.dialog.open(PromptDialog, {
            width: '400px',
            data: {title: 'Override Lap Time', prompt: 'Lap time (seconds):',  value: lap.laptime}
          });
      
          dialogRef.afterClosed().subscribe(result => {            
            if (result)
            {
                this.selectedRacer.UserChangeLapTime(lap, Number.parseFloat(result));
                this.RefreshDisplay();                
            } 
            
          });
    }

    editPitInterval(racer: Racer)
    {
        
        const dialogRef = this.dialog.open(PromptDialog, {
            width: '500px',
            data: {title: 'Modify Estimated Pit Interval', prompt: 'Enter new estimated pit interval (in minutes). 0 for default:',  value: Math.round(racer.pitInterval / 60).toString()}
          });
      
          dialogRef.afterClosed().subscribe(result => {            
            if (result)
            {
                racer.overridePitInterval = Number.parseInt(result) * 60;                
                this.RefreshDisplay();
            }
            
          });
    }

    //returns either the time for the Behind racer to catch the Ahead racer (if less than current race time)
    //or the avg lap time required for the Behind racer to catch the Ahead racer
    TimeToCatchHelper(ahead: Racer, behind: Racer, addParens: boolean): string
    {
        if (ahead == behind || !ahead || !behind)
            return ""; //nothing to display
        let timeDelta = behind.TimeDeltaToAhead(ahead, this.pitStrategyView);
        if (timeDelta < 0) //swap ahead and behind
            [ahead, behind] = [behind, ahead];
        let timeToCatch = behind.TimeToCatch(ahead, this.pitStrategyView);
        let timeRemaining = (this.endTime.getTime() - this.currentTime.getTime()) / 1000;
        if (timeToCatch > 0 && timeToCatch < timeRemaining) //they are catching, display the time to catch!
            return this.FormatTime(timeToCatch, {addParens: addParens, hms: true});
        //else they aren't going to catch in time! Figure out how fast they need to go!
        timeToCatch = behind.AvgLapTimeToCatch(ahead, this.pitStrategyView);
        let ret = this.FormatTime(timeToCatch, {addParens: false, showmsec: true});
        if (addParens) //add special parens to indicate a lap time
            return "[" + ret + "]";
    }
    
    FormatTime(sec: number, { showZero = true, addParens = false, showmsec = false, hms = false}) : string
    {
        //d.toLocaleTimeString({hour12: false});
        if (!showZero && sec == 0)
            return "";
        
        let ret: string = '';
        if (sec < 0)
            ret += '-';
        sec = Math.abs(sec);

        let h = Math.trunc(sec / 3600);
        let m = Math.trunc((sec - h * 3600) / 60);
        let s = Math.trunc(sec - h * 3600 - m * 60);
        let ms = sec - Math.trunc(sec);
     
        if (hms)
        {
            if (h)
                ret += h.toString() + 'h';
            m = Math.round((sec - h * 3600) / 60); //since we don't show seconds, round to the nearest minute
            ret += m.toString() + 'm';
        } else
        {
            if (h)
            {
                ret += h.toString() + ':';
                ret += m.toString().padStart(2, '0');
            } else //no hours, just do the minutes w/o prefix
            {
                ret += m.toString();
            }
            if (!showmsec) //round the seconds if we aren't showing mseconds
                s = Math.round(sec - h * 3600 - m * 60);
            ret += ":" + s.toString().padStart(2, '0');
            if (showmsec)
                ret += ms.toFixed(2).slice(1).toString();
        }
        if (addParens)
            return "(" + ret + ")";
        else
            return ret;
    }

    IsFCYellow(lap: Lap) : boolean
    {
        return this.raceData.IsFCYellow(lap.endTime);
    }
    /*
    
    FormatTime(sec: number, showZero: boolean = true, addParens: boolean = false, fractional: boolean = false) : string
    {
        //d.toLocaleTimeString({hour12: false});
        if (!showZero && sec == 0)
            return "";
        
        let ret: string;
        let neg = (sec < 0);
        let d: Date = new Date(Math.abs(sec) * 1000);
        let fmt = (Math.abs(sec) > 3600) ? 'H:mm:ss' : 'm:ss';
        if (fractional)
            fmt += '.SS';
        try
        {
            ret = this.datePipe.transform(d,  fmt, '+0000');
        } catch
        {
            console.error('Bad date', d);
            ret = "UNKNOWN"
        }
        if (neg)
            ret = "-" + ret;
        if (addParens)
            return "(" + ret + ")";
        else
            return ret;
    }*/

    get startTime() : Date
    {
        return this.raceData.GetTimeCheck().startTime;
    }
    get currentTime() : Date
    {
        return this.raceData.GetTimeCheck().currentTime;
    }

    get endTime() : Date
    {
        return this.raceData.GetTimeCheck().endTime;
    }

    mainTableStandardViewAll: string[] = ['select', 'position', 'name', 'laps', 'deltaNextAll', 'deltaFirstAll', 'deltaSelected', 'lastLap', 'currentLap', 'avgGreen', 'bestLap'];
    mainTableStandardViewClass: string[] = ['select', 'position', 'name', 'laps', 'deltaNextClass', 'deltaFirstClass', 'deltaSelected', 'lastLap', 'currentLap', 'avgGreen', 'bestLap'];
    mainTablePitStrategyViewAll: string[] = ['select', 'position', 'name', 'laps', 'deltaNextAll', 'deltaFirstAll', 'deltaSelected', 'pitInterval', 'pitTime','pitLeft', 'pitStops'];
    mainTablePitStrategyViewClass: string[] = ['select', 'position', 'name', 'laps', 'deltaNextClass', 'deltaFirstClass', 'deltaSelected', 'pitInterval', 'pitTime','pitLeft', 'pitStops'];
    mainTableColumns: string[] = this.mainTableStandardViewAll;
    mainTable = new MatTableDataSource<Racer>(new Array<Racer>());
    selection = new SelectionModel<Racer>(false, []);

    driverDataColumns: string[] = ['firstlap', 'name', 'laps', 'time', 'avgGreen', 'minLap'];
    driverData = new MatTableDataSource<Driver>(new Array<Driver>());

    lapDataColumns: string[] = ['number', 'time','type', 'posInClass', 'posOverall'];
    lapData = new MatTableDataSource<Lap>(new Array<Lap>());

    pitDataColumns: string[] = ['time','duration', 'type', 'interval'];
    pitData = new MatTableDataSource<Lap>(new Array<Lap>());

    fcYellowDataColumns: string[] = ['startTime','endTime', 'laps'];
    fcYellowData = new MatTableDataSource<FCYellow>(new Array<FCYellow>());
}
