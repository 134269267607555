import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Race, RaceHeroPassing, Racer, PersistHelper, FCYellow } from '../raceproc/raceproc';
import * as Pusher from 'pusher-js';
/*
export class PersistHelper
{
    set(key: string, value: any) {};
    get(key: string) : any | undefined {return undefined;};
}*/

type LoadCompleteFunction = (err: string) => void;
type RefreshViewFunction = () => void;



@Injectable({
    providedIn: 'root'
})
export class RacedataService {
  //  private raceUrl = 'assets/1073741922-1567872206834-race.txt';
//    private channelUrl = 'assets/1073741922-1567872206834-channel.txt';
 //   private raceUrl = 'assets/1073741827-1570375050969-race.txt';
//    private channelUrl = 'assets/1073741827-1570375050969-channel.txt';
    private raceUrl = 'assets/1073741923-1571491276579-race.txt';
    private channelUrl = 'assets/1073741923-1571491276579-channel.txt';

    private race: Race;
    private passings: Array<RaceHeroPassing> = new Array<RaceHeroPassing>();
    private nextPassing: number = 0;
    public pitAdjusted : boolean = false;
    constructor(private persist: PersistHelper, private http: HttpClient) 
    { 
        this.race = new Race(persist);
    }


    getTextFile() {
        /*this.http.get('assets/1073741922-1567872206834-channel.txt', {responseType: 'text'}).subscribe((data: string) => {
          //this.clickMessage ='Got it'; 
        });*/
        return this.http.get('assets/1073741922-1567872206834-race.txt', { responseType: 'text' });
    }
 
    //private proxyUrl: string = 'http://localhost:5000/racetrack1/us-central1/proxyRequest?url=';
    //private proxyUrl: string = 'https://us-central1-racetrack1.cloudfunctions.net/proxyRequest?url=';
    private proxyUrl: string = 'https://racetrack.dev/proxy?url=';
    //private raceUrl: string = 'http://racehero.io/events/moneyshiftracing-com-pittsburgh-gp';
    private baseHost = 'https://racehero.io';
    private startMarker1: string = 'requestInfo = function(){';
    private startMarker2: string = 'return ';    
    private endMarker: string = '}';
    private raceDefinition: any;
    private loadComplete: LoadCompleteFunction;
    private refreshView: RefreshViewFunction;
    LoadLiveData(raceUrl: string, loadComplete: LoadCompleteFunction, refreshView: RefreshViewFunction)
    {
        this.loadComplete = loadComplete;
        this.refreshView = refreshView;
        this.http.get(this.proxyUrl + raceUrl, { responseType: 'text' }).subscribe((data: string) => {
//            console.log(data);
            let start = data.indexOf(this.startMarker1);
            if (start >= 0)
                start = data.indexOf(this.startMarker2, start + 1);
            let end = (start >= 0) ? data.indexOf(this.endMarker, start + 1) : -1;
            if (start < 0 || end < 0)
            {
                console.error("Race info not found at: " + raceUrl);
                loadComplete("Race info not found at: " + raceUrl);
                return;                
            }
            let defString = data.substring(start + this.startMarker2.length, end + 1);
            //console.log(defString);
            try
            {
                this.raceDefinition = eval("(" + defString + ")");
            }
            catch 
            {
                console.error(`Error loading race definition: ${defString}`)
                loadComplete(`Error loading race definition: ${defString}`);
                return;
            }
            if (!this.raceDefinition.json_path_for_run || !this.raceDefinition.push_service_token || !this.raceDefinition.push_service_channel)   
            {
                console.error(`Bad race definition: ${defString}`)
                loadComplete(`Bad race definition: ${defString}`);
                return;
            }
            console.log(this.raceDefinition);
            this.GetLiveRaceData();


        });
    }

    

    private ListenForEvents(appKey: string, channelName: string)
    {
        var pusher = new Pusher.default(appKey);
        pusher.connection.bind('state_change', function(states) {
            // states = {previous: 'oldState', current: 'newState'}
            console.log(`Connection state: ${JSON.stringify(states)}`);
            
        });

        pusher.connection.bind( 'error', function( err ) {
            console.error(err && err.error);
            /*if( err.error.data.code === 4004 ) {
                console.error('Over limit!');
            }*/
        });

        var channel = pusher.subscribe(channelName);
        channel.bind('payload', (data) => { let event = "payload";
        //channel.bind_global(function (event, data) {
         //   console.log(`The event ${event} was triggered with data ${JSON.stringify(data)}`);
            if (!data)
                return;
            //fs.appendFileSync(channelLogFile, JSON.stringify({'ts' : (new Date()).getTime() ,'event' : event, 'data': data}) + "\n");
            
            try
            {
                if (event != "payload" || !data || !data.payload) {
                    console.log(`Other event: ${JSON.stringify(data)}`);
                    return;
                }
                if (data.payload.racer_sessions) {
                    for (let sess of data.payload.racer_sessions) {
                        this.race.UpdateSessionInfo(sess);
                    }


                }
                if (data.payload.passings) {
                    for (let pass of data.payload.passings) {
                        this.race.AddPassing(pass);
                    }
                }
                this.race.RecalculateRelativePositions(this.pitAdjusted);
                if (this.refreshView)
                    this.refreshView();
            } catch 
            {
                console.error(`Error processing: ${data}`)
            }    

        });

    }

    private LoadInitialRaceData(raceData: any)
    {
      
        if (!raceData.passings)
        {
            console.log("Missing passings: " + JSON.stringify(raceData));
            return;
        }

        for (let sess of raceData.racer_sessions)
        {
            this.race.UpdateSessionInfo(sess);
        }

        let allPassings = new Array<RaceHeroPassing>();
        for (let pass of raceData.passings)
        {
            pass.lap_position_array = JSON.parse("[" + pass.lap_position_array + "]");
            if (pass.lap_timing_array == "[]" ) //ugg... no entries...
                continue;
            pass.lap_timing_array = JSON.parse("[" + pass.lap_timing_array + "]");
            allPassings.push(...this.race.SummaryPassingToArray(pass));
        }
        allPassings.sort((a:RaceHeroPassing, b:RaceHeroPassing):number  => {
            return a.timestamp - b.timestamp;
        });
        for (let pass of allPassings)
        {
            this.race.AddPassing(pass);
        }
        this.RecalculateRelativePositions();
    }

    private GetLiveRaceData()
    {
        let url = this.proxyUrl + this.baseHost + this.raceDefinition.json_path_for_run;
        this.http.get(url, { responseType: 'text' }).subscribe((data: string) => {
            //console.log(data);
            try
            {
                let raceData = JSON.parse(data);
                this.LoadInitialRaceData(raceData);                
            }
            catch 
            {
                this.loadComplete(`Error processing race data: ${url}`);
                console.error(`Error processing race data: ${url} ${data}`)
                return;
            }
            this.ListenForEvents(this.raceDefinition.push_service_token, this.raceDefinition.push_service_channel);
            if (this.loadComplete)
                this.loadComplete(null);
        });
    }

    private ProcessStaticRaceData(data: string)
    {
        let entry: any = JSON.parse(data);
        if (!entry.ts)
            return;
        if (!entry.raceData)
        {
            this.loadComplete(`Error processing race data: ${data}`);
            console.error(`Error processing static race data: ${data}`)
            return;
        }
        try
        {
            this.LoadInitialRaceData(entry.raceData);
        }
        catch 
        {
            this.loadComplete(`Error processing race data: ${data}`);
            console.error(`Error processing race data: ${data}`)
            return;
        }

    }

    private ProcessStaticChannelData(data: string)
    {
        let lines = data.split('\n');
        console.log(`${lines.length} entries found`);
        for (let line of lines) {
            if (!line)
                continue; //empty
            try
            {
                let entry: any = JSON.parse(line);
                if (!entry.ts)
                {
                    console.log(`Unknown entry: ${line}`);
                    continue;
                }
                if (entry.event != "payload" || !entry.data || !entry.data.payload) {
                    console.log(`Other event: ${line}`);
                    continue;
                }
                if (entry.data.payload.racer_sessions) {
                    for (let sess of entry.data.payload.racer_sessions) {
                        this.race.UpdateSessionInfo(sess);
                    }


                }
                if (entry.data.payload.passings) {
                    for (let pass of entry.data.payload.passings) {
                        this.passings.push(pass);
                //       this.race.AddPassing(pass);
                        //   App.race.RecalculateRelativePositions();
                    }
                }
            } catch 
            {
                console.error(`Error processing: ${line}`)
            }
        }
        console.log(`${lines.length} entries loaded ${this.passings.length} passings`);             
    }

    LoadStaticData(loadComplete: LoadCompleteFunction, refreshView: RefreshViewFunction) {
        this.loadComplete = loadComplete;
        this.refreshView = refreshView;
        this.http.get(this.raceUrl, { responseType: 'text' }).subscribe((data: string) => {
            this.ProcessStaticRaceData(data);
            this.http.get(this.channelUrl, { responseType: 'text' }).subscribe((data: string) => {
                this.ProcessStaticChannelData(data);
                this.loadComplete(null);
            });
        });
      
    }

    public NextPassing(numPassings: number)
    {
        for (let i = 0; i < numPassings && this.nextPassing < this.passings.length - 1; i++)
            this.race.AddPassing(this.passings[this.nextPassing++]);
        this.RecalculateRelativePositions();
        if (this.refreshView)
            this.refreshView();
    }
    public RecalculateRelativePositions()
    {
        this.race.RecalculateRelativePositions(this.pitAdjusted);
    }

    

    public GetRacers(className: string) : Array<Racer>
    {
        return this.race.GetRacersByClass(className, this.pitAdjusted);
    }
    public GetClassList() : Array<string>
    {
        return Array.from(this.race.classes.values());
    }
    public GetTimeCheck() : {startTime: Date, currentTime: Date, endTime: Date, totalDuration: number} //[Date, Date, Date]
    {
        //return [this.race.startTime, this.race.currentTime, this.race.endTime];
        return {startTime: this.race.startTime, currentTime: this.race.currentTime, endTime: this.race.endTime, totalDuration: this.race.duration};
    }
    public SetDuration(d: number)
    {
        this.race.duration = d;
    }
    public GetFCYellows() : Array<FCYellow>
    {
        return this.race.fcYellow.fcYellows;
    }
    public IsFCYellow(endTime: Date): boolean
    {
        return this.race.fcYellow.IsFCYellow(endTime)[0];
    }
}
