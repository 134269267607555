import { deepStrictEqual } from 'assert';

export enum LapFlag {PitStop, PitInOut, Yellow, LongPit, Green, Unknown, Missing, LAST_LAP_FLAG};
//enum LapFlagType {Auto, Manual};

var startTimestamp : number = 0;
var currentTimestamp : number = 0;
var duration: number = 24 * 60 * 60;
var gPersist: PersistHelper; //global link to the persist helper (assumes same one for all races)

export class PersistHelper
{
    set(key: string, value: any) : void
    {

    }
    get(key: string) : any | undefined
    {
        return undefined;
    }
}

export class Lap
{
    num: number;
    endTime: Date;
    laptime: number;
    pitStopGreenTime: number = 0; //if it's a pit stop, record the recent green time so we can adjust for pitstop time
    pitInterval: number = 0;
    
    detectedLapFlag: LapFlag;
    overrideLapFlag: LapFlag | null = null;
    //lapFlagType: LapFlagType; 
    positionOverall: number;
    positionInClass: number;


    get lapFlag(): LapFlag
    {
        if (this.overrideLapFlag != null)
            return this.overrideLapFlag;
        return this.detectedLapFlag;
    }

    get startTime() : Date
    {
        return new Date(this.endTime.getTime() - this.laptime * 1000);
    }
    get lapFlagString() : string
    {
        return LapFlag[this.lapFlag];
    }

    get isPitStop(): boolean 
    {        
        return (this.lapFlag == LapFlag.PitStop || this.lapFlag == LapFlag.LongPit);
    }
    get estimatedPitLength() : number
    {
        if (!this.isPitStop)
            return 0;
        return (this.laptime - this.pitStopGreenTime);
    }

    public constructor(num: number, endTime: Date, laptime: number, positionOverall: number = 0, positionInClass: number = 0, detectedLapFlag: LapFlag = LapFlag.Unknown)
    {
        this.num = num;
        this.endTime = endTime;
        this.laptime = laptime;
        this.detectedLapFlag = detectedLapFlag;
        this.positionOverall = positionOverall;
        this.positionInClass = positionInClass;
         
    }
}

class RaceHeroSession
{
    racer_session_id: number;
    name: string;
    racer_class: string;
    racer_number: string;
    start_position: any;
    racer_attributes: any; 
}

export class RaceHeroPassing
{
    racer_session_id: number;
    current_lap: number;
    start_position_in_run: number;
    start_position_in_class: number;
    current_flag_type: string; //"Finish Flag", "WarmUp Flag"
    latest_lap_number: number;
    best_overall: boolean;
    position_in_run: number;
    position_in_class: number;
    best_lap_number: number;
    best_lap_time: string; //"00:02:12.008"
    best_lap_time_seconds: number;
    laps_since_pit: number;
    last_pit_lap: number;
    pit_stops: number;
    is_current_pit_lap: boolean;
    total_seconds: number;
    last_lap_time: string; //"00:02:16.944"
    last_lap_time_seconds: number;
    timestamp: number;
    //lap_position_array: Array<any>;
    //lap_timing_array: Array<any>;
    lap_position_array: Array<[number, number]>;
    lap_timing_array: Array<[number, number]>;
}

const average = (array: number[]) => array.reduce((a, b) => a + b) / array.length;
export class Driver
{
    firstLap: number; 
    firstLapEndTime: Date;
    totalLaps: number = 0; 
    totalTime: number = 0;
    greenLaps: number = 0;
    greenTime: number = 0;
    minLapTime: number = 0;
    minLapNumber: number = 0;
    _name: string;
    uniqueID: string;
    public constructor(firstLap: number, uniqueID: string)
    {
        this.firstLap = firstLap;
        this.uniqueID = uniqueID;
        if (gPersist) //look up a stored value if we have one
            this._name = gPersist.get(this.uniqueID + "-name");
        if (!this._name)
            this._name = `Unknown[${firstLap}]`;
    }
    get name() : string
    {
        return this._name;
    }
    set name(name: string)
    {
        this._name = name;
        if (gPersist)
            gPersist.set(this.uniqueID + "-name", name);
    }
    get avgGreenTime() : number
    {
        if (this.greenLaps == 0)
            return 0
        return this.greenTime / this.greenLaps;
    }
    public RecordLap(lap: Lap)
    {
        if (lap.num == this.firstLap)
            this.firstLapEndTime = lap.endTime;
        this.totalLaps++;
        this.totalTime += lap.laptime;
        if (lap.lapFlag == LapFlag.Green)
        {
            this.greenLaps++;
            this.greenTime += lap.laptime;
        }
        if (lap.lapFlag != LapFlag.Unknown && lap.lapFlag != LapFlag.Missing && lap.laptime > 0 && (this.minLapTime == 0 || this.minLapTime > lap.laptime))
        {
            this.minLapTime = lap.laptime;
            this.minLapNumber = lap.num;
        }
    }
}

class RelativePosition
{
    behind: Racer;
    ahead: Racer;
    first: Racer;
}

export class Racer
{
    sessionID: number;
    name: string;
    class: string;
    number: string;
    yellowHelper: FullYellowHelper;
    
    lapCountByType: Array<number> = new Array<number>(LapFlag.LAST_LAP_FLAG);//number of laps of each type
    lapTimeByType: Array<number> = new Array<number>(LapFlag.LAST_LAP_FLAG); //total time for each type

    laps: Array<Lap> = new Array<Lap>();
    passings: Array<RaceHeroPassing> = new Array<RaceHeroPassing>(); //we store this so we can re-process to re-generate our laps if needed


    readonly cMaxRecentGreen = 15;
    readonly cHardMinTime = 10; 
    readonly cPitInOutMinDiff = 5;
    readonly cPitInOutMaxDiff = 30;
    readonly cLongPitDiff = 8 * 60;
    readonly cPitDiff = 60;
    readonly cDefaultPitTime = 180;
    readonly cDefaultPitInterval = 60 * 120;
    recentGreen: Array<Lap> = new Array<Lap>();
    drivers: Array<Driver> = new Array<Driver>();

    
    maxPitInterval: number = 0;
    _overridePitInterval: number = 0;
    posInClass: RelativePosition = new RelativePosition();
    posOverall: RelativePosition = new RelativePosition();
/*
    prevOverall: Racer;
    prevInClass: Racer;
    nextOverall: Racer;
    nextInClass: Racer;
    firstOverall: Racer;*/

    public constructor(session: RaceHeroSession, yellowHelper: FullYellowHelper)
    {
        
        this.sessionID = session.racer_session_id;
        this.name = session.name;
        this.class = session.racer_class;
        this.number = session.racer_number;
        this.yellowHelper = yellowHelper;
        this.clear();
        //init the lap counts by type...
        if (gPersist)
            this._overridePitInterval = Number.parseInt(gPersist.get(this.sessionID + "-pi") || "0");
        
    }
    private clear()
    {
        this.laps.length = 0;
        this.lapCountByType.fill(0)
        this.lapTimeByType.fill(0)
        this.drivers.length = 0;
        this.passings.length = 0;
        this.drivers.push(new Driver(0, this.sessionID + "-0")); //add a special driver to track "everyone"
        this.drivers.push(new Driver(1, this.sessionID + "-1")); //add the first real driver
        this.laps.push(new Lap(0, new Date(), 0, 0, 0, LapFlag.Missing)); //add an empty entry so lap numbers match array indexes
    }
    public get changed() : string
    {
        //console.log(`${this.name} - ${this.laps.length} animate check`);
        //return this.lapsComplete;
        return this.name + "-" + this.lapsComplete.toString();
    }

    public get overridePitInterval() : number
    {
        return this._overridePitInterval;
    }
    public set overridePitInterval(pi: number)
    {
        this._overridePitInterval = pi;
        if (gPersist)
            gPersist.set(this.sessionID + "-pi", pi.toString());
    }
    public get minLapTime() : number 
    {
        return this.drivers[0].minLapTime;   
    }
    public get minLapNumber() : number 
    {
        return this.drivers[0].minLapNumber;   
    }
    public get minLapTimeCurrentDriver() : number 
    {
        return this.drivers[this.drivers.length - 1].minLapTime;   
    }
    public get minLapNumberCurrentDriver() : number 
    {
        return this.drivers[this.drivers.length - 1].minLapNumber;   
    }
    public get avgGreenCurrentDriver() : number
    {
        return this.drivers[this.drivers.length - 1].avgGreenTime;   
    }
    public get avgGreenTime() : number
    {
        return this.drivers[0].avgGreenTime;   
    }
    public get lapsComplete() : number
    {
        return this.laps[this.laps.length - 1].num;
    }
    public get lastLapCompleteAt(): Date
    {
        return (this.lapsComplete == 0) ? new Date(startTimestamp * 1000) : this.laps[this.laps.length - 1].endTime;
    }
    public get lastLapTime(): number
    {
        return (this.lapsComplete == 0) ? 0 : this.laps[this.laps.length - 1].laptime;
    }
    public get currentLapTime() : number
    {
        return (this.lapsComplete == 0) ? 0 : (currentTimestamp - this.laps[this.laps.length - 1].endTime.getTime() / 1000);
    }
    public get positionOverall(): number
    {
        return this.laps[this.laps.length - 1].positionOverall;
    }
    public get positionInClass(): number
    {
        return this.laps[this.laps.length - 1].positionInClass;
    }

    public get avgRecentGreen() : number
    {
        if (this.recentGreen.length)
            return this.recentGreen.reduce<number>((a,b) => (a+b.laptime), 0) / this.recentGreen.length;
        else
            return 0;
    }
    public get lastPitLap(): number
    {
        return this.drivers[this.drivers.length - 1].firstLap;
    }
    public get lastPitAt(): Date
    {        
        let ret = this.drivers[this.drivers.length - 1].firstLapEndTime;
        if (!ret)
            return new Date(startTimestamp * 1000);
        else
            return ret;
    }
    public get timeSinceLastPit() : number
    {
        return currentTimestamp - this.lastPitAt.getTime() / 1000;
    }
    public get pitInterval() : number
    {
        if (this.overridePitInterval)
            return this.overridePitInterval;
        return (this.maxPitInterval == 0) ? this.cDefaultPitInterval : this.maxPitInterval;
    }
    public get inPitWindow() : boolean
    {
        //we know how many pit stops we HAVE to make
        let remain = this.pitStopsRemaining;
        if (remain == 0)
            return false; //no need to pit anymore!
        //given that number of it stops..
        //if we pit now, how far could we run?
        let endurance = remain * this.pitInterval;
        return (endurance > this.timeLeft);     
    }
    public get timeTillNextPit() : number
    {
        let ret = this.pitInterval - this.timeSinceLastPit;
        return Math.max(ret, 0); //if we should have it already, return 0
    }

    public get pitStopsRemaining() : number
    {
        let timeLeft = this.timeLeft;
        
        let timeTillNext = this.timeTillNextPit; 
        if (timeTillNext > timeLeft)
            return 0;
        timeLeft -= timeTillNext;
        return 1 + Math.trunc(timeLeft / this.pitInterval); 
        
    }

    public get pitTimeRemaining() : number
    {
        let pittime = this.avgPitTime;
        if (pittime == 0)
            pittime = this.cDefaultPitTime;
        return this.pitStopsRemaining * pittime;
    }

    public get totalPitTime() : number
    {
        //note: these "lap times" have already been adjusted to subtracted out the green portion of the lap
        let ret = this.lapTimeByType[LapFlag.PitStop] + this.lapTimeByType[LapFlag.LongPit];
        //if in pits, add the current lap time
        if (this.inPit)
            ret += this.currentLapTime - this.avgGreenTime;
        return ret;
    }
    public get avgPitTime() : number
    {
        if (this.lapCountByType[LapFlag.PitStop] == 0)
            return 0;
        return this.lapTimeByType[LapFlag.PitStop] / this.lapCountByType[LapFlag.PitStop];
    }
    public get inPit() : boolean
    {
        //if the current lap is LONG (probably a pit)
        return (this.currentLapTime > this.avgRecentGreen + this.cPitDiff);

    }
    public get timeLeft() : number
    {
        let timeLeft = duration - (currentTimestamp - startTimestamp);
        return (timeLeft < 0) ? 0 : timeLeft;
        
    }

    public get pitStops() : number
    {
        let ret = this.lapCountByType[LapFlag.PitStop];
        if (this.currentLapTime > this.avgRecentGreen + this.cPitDiff && this.currentLapTime < this.avgRecentGreen + this.cLongPitDiff)
            ret++;
        return ret;
    }
    public get longPitStops() : number
    {
        let ret = this.lapCountByType[LapFlag.LongPit];
        if (this.currentLapTime > this.avgRecentGreen + this.cLongPitDiff)
            ret++;
        return ret;
    }

    public TimeToCatch(ahead: Racer, includePits: boolean = false) : number
    {
        let ret = this.TimeDeltaToAhead(ahead, includePits);
        if (ret == 0)
            return 0;
        if (ret < 0) //they are behind us, return their time to catch US
            return ahead.TimeToCatch(this, includePits);
        //calculate how much we are gaining per lap
        let diff = ahead.avgRecentGreen - this.avgRecentGreen;
        if (diff < 0) //they are gaining on us!
            return 0;
        return (ret / diff) * this.avgRecentGreen; //the number of laps it will take to catch them, times our average recent lap time
    }

    public AvgLapTimeToCatch(ahead: Racer, includePits: boolean = false) : number
    {
        let timeDelta = this.TimeDeltaToAhead(ahead, includePits);
        if (timeDelta == 0)
            return 0;
        if (timeDelta < 0) //they are behind us, return their needed lap time to catch us
            return ahead.AvgLapTimeToCatch(this, includePits);

        
        //figure out approx how many laps the ahead car has left
        //the basic equation is the same as TimeDeltaToAhead, but we know timeleft and want to solve for the avgGreenLap time!
        //timeLeft = (timeDelta / (ahead.avgRecentGreen - this.avgRecentGreen)) * this.avgRecentGreen

        let avgGreenLap = (ahead.avgRecentGreen * this.timeLeft) / (timeDelta + this.timeLeft);
        return avgGreenLap;
    }

    public TimeDeltaToAhead(ahead: Racer, includePits: boolean = false) : number
    {
        if (!ahead || ahead == this)
            return 0;
        if (ahead.lapsComplete < this.lapsComplete || (ahead.lapsComplete == this.lapsComplete && ahead.lastLapCompleteAt.getTime() > this.lastLapCompleteAt.getTime()))
            return -ahead.TimeDeltaToAhead(this, includePits); //ahead is actually behind us, return a negative difference
        let curLapTimeA = Math.max(ahead.lastLapCompleteAt.getTime() / 1000, this.lastLapCompleteAt.getTime() / 1000) - (ahead.lastLapCompleteAt.getTime() / 1000);
        let curLapTimeB = Math.max(ahead.lastLapCompleteAt.getTime() / 1000, this.lastLapCompleteAt.getTime() / 1000) - (this.lastLapCompleteAt.getTime() / 1000);
        let myRecentGreen = this.avgRecentGreen;
        if (!myRecentGreen) //if we don't have a recent green time, use the other guy's time as a proxy... we probably aren't even running
            myRecentGreen = ahead.avgRecentGreen;
        let timeAhead = (ahead.lapsComplete - this.lapsComplete) * myRecentGreen + Math.min(ahead.avgRecentGreen, curLapTimeA) - Math.min(myRecentGreen, curLapTimeB);
        if (includePits)
            timeAhead += (this.pitTimeRemaining - ahead.pitTimeRemaining); //adjust for the relative pit time remaining - note: this may put the other car ahead!
        return timeAhead;
    }


    private FindDriverForLap(lap: Lap) : Driver
    {
        let driver: Driver;

        if (lap.isPitStop) //need to create a new driver
        {
            driver = new Driver(lap.num,this.sessionID + "-" + lap.num.toString());
            this.drivers.push(driver);
            return driver;
        }
        //reverse search for it... 
        for (let i = this.drivers.length - 1 ; i > 0 ; i--)
            if (lap.num >= this.drivers[i].firstLap)
                return this.drivers[i];        
                
    }

    private AdjustLapCounts(lapFlag: LapFlag, deltaLaps: number, deltaTime: number)
    {
        this.lapCountByType[lapFlag] += deltaLaps;
        this.lapTimeByType[lapFlag] += deltaTime;
    }

    private AddLap(lap: Lap)
    {
        let avgRecent = this.avgRecentGreen;
        let prevLap:Lap = this.laps[lap.num - 1];

        switch (lap.lapFlag)
        {
            case LapFlag.Unknown:
                console.warn(`Unexpected fast lap ${this.sessionID} ${this.name} ${JSON.stringify(lap)}`);
                break;
            case LapFlag.Green:
                this.recentGreen.push(lap);
                if (this.recentGreen.length > this.cMaxRecentGreen)
                    this.recentGreen.shift();
                break;
            case LapFlag.PitStop:
            case LapFlag.PitInOut:
                //check if the previous lap was slow, may be a pit-in lap
                if (prevLap.laptime > avgRecent + this.cPitInOutMinDiff && prevLap.laptime < avgRecent + this.cPitInOutMaxDiff) //if between 10 & 30 seconds long, call it a in/out lap
                    this.ChangeDetectedLapType(prevLap, LapFlag.PitInOut);
                break;
        }
        //update the lap counts by type
        let oldLap = this.laps[lap.num];
        if (oldLap) //replacing an existing lap
        {
            if (oldLap.isPitStop) //need to remove it from the timings accordingly            
            {
                this.AdjustLapCounts(oldLap.lapFlag, -1, -oldLap.estimatedPitLength);
                this.AdjustLapCounts(LapFlag.Green, -1, -oldLap.pitStopGreenTime);

            } else //just adjust the timings for this lap type
                this.AdjustLapCounts(oldLap.lapFlag, -1, -oldLap.laptime);

        }
        if (lap.isPitStop)
        {
            this.AdjustLapCounts(lap.lapFlag, 1, lap.estimatedPitLength);
            this.AdjustLapCounts(LapFlag.Green, 1, lap.pitStopGreenTime);
        } else //just adjust normally
            this.AdjustLapCounts(lap.lapFlag, 1, lap.laptime);


        this.laps[lap.num] = lap;

        if (lap.isPitStop) //see if we should record the pit interval
        {
            for (let i = lap.num - 1 ; i > 0 ; i--)
            {
               
                if (this.laps[i].isPitStop || i == 1) //if we found a pit stop, or the first lap
                {
                    lap.pitInterval = (lap.endTime.getTime() - this.laps[i].endTime.getTime()) / 1000;
                    if (lap.lapFlag == LapFlag.LongPit)
                        lap.pitInterval -= lap.estimatedPitLength; //don't include the time we were sitting in the pit!
                    if (lap.pitInterval > this.maxPitInterval)
                        this.maxPitInterval = lap.pitInterval;
                    break;
                }
            }
        }

        //record the lap for the driver
        let driver: Driver = this.FindDriverForLap(lap);        
        driver.RecordLap(lap);
        this.drivers[0].RecordLap(lap); //record for "everyone"
    }

    public UserChangeLapType(lap: Lap, lapFlag: LapFlag)
    {
        gPersist.set(this.sessionID + '-lap-' + lap.num.toString(), LapFlag[lapFlag]);
        this.ReprocessPassings();
    }

    public UserChangeLapTime(lap: Lap, laptime: number)
    {
        gPersist.set(this.sessionID + '-laptime-' + lap.num.toString(),laptime);
        this.ReprocessPassings();
    }

    private ChangeDetectedLapType(lap: Lap, lapFlag: LapFlag)
    {

        if (lap.overrideLapFlag != null) //just change the detected value, we don't need to update anything else since it's overriden anyway
        {
            lap.detectedLapFlag = lapFlag;
            return;
        }
    
        if (lap.isPitStop) //need to remove it from the timings accordingly            
        {
            this.AdjustLapCounts(lap.lapFlag, -1, -lap.estimatedPitLength);
            this.AdjustLapCounts(LapFlag.Green, -1, -lap.pitStopGreenTime);
        } else //just adjust the timings for this lap type
            this.AdjustLapCounts(lap.lapFlag, -1, -lap.laptime);


        if (lap.lapFlag == LapFlag.Green && lapFlag != LapFlag.Green)
        {
            //if it's in the recent greens, remove it
            let idx = this.recentGreen.indexOf(lap);
            if (idx)
                this.recentGreen.splice(idx, 1);
            //todo: if changing a green lap, remove it from driver green times?
        }
        lap.detectedLapFlag = lapFlag; 

        if (lap.isPitStop)
        {
            this.AdjustLapCounts(lap.lapFlag, 1, lap.estimatedPitLength);
            this.AdjustLapCounts(LapFlag.Green, 1, lap.pitStopGreenTime);
        } else //just adjust normally
            this.AdjustLapCounts(lap.lapFlag, 1, lap.laptime);

    }

    private AddLapGuessType(lap: Lap)
    {
        let userLapFlag = gPersist.get(this.sessionID + '-lap-' + lap.num.toString());
        let avgRecent = this.avgRecentGreen;
        let allowedGreenDiff = (this.recentGreen.length < 10) ? 30 : 15;
        let prevLap:Lap = this.laps[lap.num - 1];

         if (userLapFlag)
            lap.overrideLapFlag = LapFlag[<string>(userLapFlag)];
        else
            lap.overrideLapFlag = null;

        let [isFCYellow, yellowLapTime] = this.yellowHelper.IsFCYellow(lap.endTime);
        let avgRecentForPits = (isFCYellow) ? yellowLapTime : avgRecent; //detect pits using the yellow lap time instead of avg green time

    
        if (lap.laptime < this.cHardMinTime)
            lap.detectedLapFlag = LapFlag.Unknown;
        else if (prevLap.lapFlag == LapFlag.PitStop && lap.laptime > avgRecent + this.cPitInOutMinDiff && lap.laptime < avgRecent + this.cPitInOutMaxDiff)
            lap.detectedLapFlag = LapFlag.PitInOut; //if the last lap was a pit, and this lap is between 5 and 30 seconds slow, catagorize as a pit in/out
        else if (avgRecent == 0 || lap.laptime < avgRecent + allowedGreenDiff) //assume it's green... 
            lap.detectedLapFlag = LapFlag.Green;
        else if (lap.laptime > avgRecentForPits + this.cLongPitDiff) //garage/red flag
            lap.detectedLapFlag = LapFlag.LongPit;
        else if (lap.laptime > avgRecentForPits + this.cPitDiff) //pit lap
            lap.detectedLapFlag = LapFlag.PitStop;
        else //we should have a lap that is between allowedGreenDiff & cPitDiff(60) seconds - catagorize as a yellow
            lap.detectedLapFlag = LapFlag.Yellow;
        if (lap.isPitStop)
            lap.pitStopGreenTime = avgRecentForPits;

        this.AddLap(lap);
    }

    public AddPassing(passing: RaceHeroPassing)
    {
        let userLapTime = gPersist.get(this.sessionID + '-laptime-' + passing.current_lap.toString());
        if (userLapTime)
            passing.last_lap_time_seconds = <number>(userLapTime);
        this.passings[passing.current_lap] = passing;
        if (passing.current_lap < this.laps.length) //duplicate or missing lap
        {
            if (this.laps[passing.current_lap].lapFlag == LapFlag.Missing) //found a missing lap
            {
                console.log(`Found a missing lap. Passing lap: ${passing.current_lap}. Current laps: ${this.laps.length - 1}. ${JSON.stringify(passing)}`);
                //this.laps[passing.current_lap] = new Lap(passing.current_lap, new Date(passing.timestamp * 1000), passing.last_lap_time_seconds, LapFlag.Unknown, LapFlagType.Auto);
                this.AddLapGuessType(new Lap(passing.current_lap, new Date(passing.timestamp * 1000), passing.last_lap_time_seconds, passing.position_in_run, passing.position_in_class));
            } else
            {
                console.log(`Found a duplicate lap (penalty?). Passing lap: ${passing.current_lap}. Current laps: ${this.laps.length - 1}. ${JSON.stringify(this.laps[passing.current_lap])} ${JSON.stringify(passing)}`);
                this.AddLapGuessType(new Lap(passing.current_lap, new Date(passing.timestamp * 1000), passing.last_lap_time_seconds, passing.position_in_run, passing.position_in_class));
                return;
            }
        } else
        {
            if (passing.current_lap > this.laps.length) //we are missing laps!
            {
                //console.warn(`Passing for non-contiguous lap. Passing lap: ${passing.current_lap}. Current laps: ${this.laps.length - 1}. ${JSON.stringify(passing)}`);
                //add extra laps... 
                while (passing.current_lap > this.laps.length)
                    this.AddLap(new Lap(this.laps.length, new Date(passing.timestamp * 1000), 0, passing.position_in_run, passing.position_in_class, LapFlag.Missing));
            }
            this.AddLapGuessType(new Lap(passing.current_lap, new Date(passing.timestamp * 1000), passing.last_lap_time_seconds, passing.position_in_run, passing.position_in_class));
        }
    }

    public ReprocessPassings()
    {
        //rebuilds the lap array from the passings
        let oldPassings = new Array<RaceHeroPassing>(...this.passings); //copy it out before it gets cleared
        this.clear();
        for (let passing of oldPassings)
        {
            if (passing)
                this.AddPassing(passing);
        }
    }

}

class FCYellowLap
{
    startTime: Date;
    endTime: Date;
    lapTime: number;
}

export class FCYellow
{
    constructor(laps: Array<FCYellowLap>)
    {
        this.laps = laps;
    }
    public get startTime(): Date
    {
        return this.laps[0].startTime;
    }
    public get endTime(): Date
    {
        return this.laps[this.laps.length - 1].endTime;
    }
    public AvgLapTime(endTime: Date): number | undefined
    {
        for (let lap of this.laps)
        {
            if (endTime >= lap.startTime && endTime < lap.endTime)
                return lap.lapTime;
        }
        return undefined;
    }
    public get lapCount()
    {
        return this.laps.length;
    }
    public laps: Array<FCYellowLap>;
}

class FullYellowHelper
{
    private fcYellowActive: boolean = false;
    private firstYellowLapEndTime: Date;
    private seenPitSessions: Map<number, number> = new Map<number, number>();
    private yellowLaps: Array<FCYellowLap>;
    public fcYellows: Array<FCYellow> = new Array<FCYellow>();
    private detectedGreens = 0;
    private detectedGreenTime: Date;
    readonly cDetectedGreenThreshhold = 3; //must detect 3 green laps to end a FC yellow or clear the pit sessions
    readonly cDetectionThreshhold = 0.5; //if more than half the field is pit/yellow (without 3 greens), detect a FC yellow
    private lastActiveCount = 0;
    private seenCars: Set<number> = new Set<number>();

    public IsFCYellow(endTime: Date) : [boolean, number]
    {
        for (let fc of this.fcYellows)
        {
            if (endTime >= fc.startTime && endTime < fc.endTime)
                return [true, fc.AvgLapTime(endTime)];
        }
        return [false, 0];
    }
    private Median(array: Array<number>) : number
    {
        array.sort(function(a, b) {
          return a - b;
        });
        if (array.length == 0)
            return 0;
        var mid = array.length / 2;
        return (mid % 1) ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
    }

    public AddPassing(passing: RaceHeroPassing, detectedLapType: LapFlag) : boolean
    {
        if (!passing.current_lap || !passing.last_lap_time_seconds)
            return false; //not a valid lap
        //keep a rough track of the number of active cars
        if (this.seenCars.has(passing.racer_session_id))
        {
            this.lastActiveCount = this.seenCars.size;
            this.seenCars.clear();
        }
        this.seenCars.add(passing.racer_session_id);
            ///console.log(`${passing.last_lap_time_seconds}   ${LapFlag[detectedLapType]}`);
        ///console.log(`       LAP ${LapFlag[detectedLapType]} | ${new Date(passing.timestamp * 1000)} | ${passing.last_lap_time_seconds} | ${this.seenPitSessions.size} PASSINGS | ${this.detectedGreens} GREENS | CAR: ${passing.racer_session_id}`);

        if (detectedLapType == LapFlag.Green)
        {
            if (this.detectedGreens == 0)
                this.detectedGreenTime = new Date(passing.timestamp * 1000);
            if (++this.detectedGreens < this.cDetectedGreenThreshhold)
                return false; //don't take any action
            this.detectedGreens = 0;
            if (this.fcYellowActive) 
            {
                //add the last lap
                let lapEndTime = this.detectedGreenTime; //use the time we first detected the green //new Date(passing.timestamp * 1000);
                //when yellow ends, use the median time of the last set of laps under yellow as the yellow lap time - we can't use this lap, since it's green and not representative
                let yellowLapTime = this.Median(Array.from(this.seenPitSessions.values()));
                this.yellowLaps.push({startTime: this.firstYellowLapEndTime, endTime: lapEndTime, lapTime: yellowLapTime});    
                console.log(`   YELLOW LAP | ${this.firstYellowLapEndTime} -> ${lapEndTime} | ${this.yellowLaps[this.yellowLaps.length - 1].lapTime} LAPTIME |FIRST CAR: ${passing.racer_session_id}`);
                console.log(`END YELLOW | ${this.yellowLaps[0].startTime} -> ${lapEndTime} | ${this.yellowLaps.length} LAPS`);
                this.fcYellowActive = false; 
                this.fcYellows.push(new FCYellow(this.yellowLaps));
                this.yellowLaps = null;
                this.seenPitSessions.clear(); //clear out any seen pit sessions, we aren't full course yellow
                return true;               
            } else
            {
                this.seenPitSessions.clear(); //clear out any seen pit sessions, we aren't full course yellow
                return false;
            }
        }
        if (detectedLapType != LapFlag.PitStop && detectedLapType != LapFlag.LongPit && detectedLapType != LapFlag.Yellow)
            return false; //if it's not one of those types (and not green) then just ignore it for the purpose of calculating FC yellow
        if (this.detectedGreens > 0) //decrease the green detector once we see non-green
            this.detectedGreens--; 
        if (!this.fcYellowActive &&   //if we haven't detected FC yellow yet,
            (this.seenPitSessions.has(passing.racer_session_id) ||  //check if it's in the list of sessions
            (this.lastActiveCount > 0 && this.seenPitSessions.size > this.lastActiveCount * this.cDetectionThreshhold))) //or if we've detected over the threshhold
        {
            this.fcYellowActive = true; //we are triggering the FC yellow, based on the threshhold being hit (or a full set of cars going around)
            this.yellowLaps = new Array<FCYellowLap>();
            console.log(`START YELLOW | ${this.firstYellowLapEndTime} | ${this.seenPitSessions.size} YELLOWS ${this.lastActiveCount} ACTIVE`);
        }
        
        if (!this.fcYellowActive && this.seenPitSessions.size == 0) //if it's still not active, this is potentially the first lap
            this.firstYellowLapEndTime = new Date(passing.timestamp * 1000);

        //if the FC yellow is active - do we need to trigger a new lap or not?
        if (this.fcYellowActive && this.seenPitSessions.has(passing.racer_session_id)) //we've already gone around once - trigger a new lap
        {
            let lapEndTime = new Date(passing.timestamp * 1000);
            //let yellowLapTime = this.Median(Array.from(this.seenPitSessions.values()));
            let yellowLapTime = passing.last_lap_time_seconds; //while under yellow, use the time of the first person to complete the full lap during this period as the "avg" time
            this.yellowLaps.push({startTime: this.firstYellowLapEndTime, endTime: lapEndTime, lapTime: yellowLapTime});
            console.log(`   YELLOW LAP | ${this.firstYellowLapEndTime} -> ${lapEndTime} | ${this.yellowLaps[this.yellowLaps.length - 1].lapTime} LAPTIME |FIRST CAR: ${passing.racer_session_id}`);
            this.firstYellowLapEndTime = lapEndTime;
            this.seenPitSessions.clear(); //clear out to start a new lap
            this.detectedGreens = 0;
        } 
        this.seenPitSessions.set(passing.racer_session_id, passing.last_lap_time_seconds); //add to the list for the current lap, set the lowest yellow lap time
        return false;
    }
}

export class Race
{
    racers: Map<number, Racer> = new Map<number, Racer>();
    classes: Set<string> = new Set<string>();
    fcYellow: FullYellowHelper = new FullYellowHelper();

    public constructor(private persist: PersistHelper = undefined)
    {
        gPersist = persist;
        if (gPersist)
            duration = gPersist.get("duration") || duration;
        //if (persist)
            //persist.set("test value", "testkey");
    }

    public get startTime() : Date
    {
        return new Date(startTimestamp * 1000);        
    }
    public get currentTime() : Date
    {
        return new Date(currentTimestamp * 1000);
    }
    public get endTime() : Date
    {
        return new Date((startTimestamp + duration) * 1000);    
    }

    public get duration() : number
    {
        return duration;
    }
    public set duration(d: number)
    {
        duration = d;
        if (gPersist)
            gPersist.set("duration", d);
    }

    public UpdateSessionInfo(session: RaceHeroSession)
    {
        if (!this.racers.has(session.racer_session_id))
        {
            this.racers.set(session.racer_session_id, new Racer(session, this.fcYellow));
        }
        this.classes.add(session.racer_class);
        //todo: update any values? Do they change?
    }

    private NameFromSess(sessionID: number): string
    {
        let racer: Racer = this.racers.get(sessionID);
        if (racer)
            return racer.name;
        else
            return "(UNKNOWN)"; 
    }
/* 
//original version that did it one racer at a time, but doesn't allow us to reconstruct positions internally on each lap...

    public AddSummaryPassing(passing: RaceHeroPassing)
    {
        //re-creates each lap based on the summary
        console.log(`st ${passing.timestamp - passing.total_seconds}`);
        let currentTimestamp = passing.timestamp - passing.total_seconds; //this is the start time for the race
        for (let i = 0 ; i < passing.lap_timing_array.length ; i++)
        {
            passing.current_lap = passing.lap_timing_array[i][0];
            passing.last_lap_time_seconds = passing.lap_timing_array[i][1] / 1000;
            currentTimestamp += passing.last_lap_time_seconds;
            passing.timestamp = currentTimestamp;
            //find the position in run, should just be one off... 
            if ((passing.lap_position_array.length > i + 1) && (passing.lap_position_array[i + 1][0] == passing.current_lap))
                passing.position_in_run = passing.lap_position_array[i + 1][1];
            else
                for (let j = 0 ; j < passing.lap_position_array.length ; j++)
                    if (passing.lap_position_array[j][0] == passing.current_lap)
                    {
                        passing.position_in_run = passing.lap_position_array[j][1];
                        break;
                    }
            //todo: position in class isn't given, need to figure out how to calculate afterwards... 
            passing.position_in_class = 0;
            this.AddPassing(passing);  
        }
    }
    */
   public SummaryPassingToArray(summaryPassing: RaceHeroPassing) : Array<RaceHeroPassing>
    {
        //re-creates each lap based on the summary
        let allPassings = new Array<RaceHeroPassing>();
        //console.log(`st ${summaryPassing.racer_session_id} ${summaryPassing.timestamp - summaryPassing.total_seconds}`);
        //first, traverse the list backwards to set the lap timestamp based on current timestamp
        //we can't go forwards, becase there may be missing laps at the start, but summaryPassing.timestamp should always refer to the last lap end time
        let currentTimestamp = summaryPassing.timestamp;
        for (let i = summaryPassing.lap_timing_array.length - 1 ; i >= 0 ; i--)
        {
            let passing = new RaceHeroPassing();
            passing.racer_session_id = summaryPassing.racer_session_id;            
            passing.current_lap = summaryPassing.lap_timing_array[i][0];
            if (!passing.current_lap) //no lap number
                continue;
            passing.last_lap_time_seconds = summaryPassing.lap_timing_array[i][1] / 1000;
            passing.timestamp = currentTimestamp;
            currentTimestamp -= passing.last_lap_time_seconds;
            //find the position in run, should just be one off... 
            if ((summaryPassing.lap_position_array.length > i + 1) && (summaryPassing.lap_position_array[i + 1][0] == summaryPassing.current_lap))
                passing.position_in_run = summaryPassing.lap_position_array[i + 1][1];
            else
                for (let j = 0 ; j < summaryPassing.lap_position_array.length ; j++)
                    if (summaryPassing.lap_position_array[j][0] == summaryPassing.current_lap)
                    {
                        passing.position_in_run = summaryPassing.lap_position_array[j][1];
                        break;
                    }
            //todo: position in class isn't given, need to figure out how to calculate afterwards... 
            passing.position_in_class = 0;
            allPassings.unshift(passing); //add to the front, since we are going in reverse order
        }
        return allPassings;
    }

    public AddPassing(passing: RaceHeroPassing)
    {
        if (passing.timestamp > currentTimestamp)
            currentTimestamp = passing.timestamp;
        if (passing.current_lap < 1) //not a real lap yet
        {
            console.log(`Passing before green ${this.NameFromSess(passing.racer_session_id)} lap ${passing.current_lap} ${passing.current_flag_type}`);
            return;
        }
        if (startTimestamp == 0 || passing.timestamp - passing.last_lap_time_seconds < startTimestamp)
            startTimestamp = passing.timestamp - passing.last_lap_time_seconds;

        let racer: Racer = this.racers.get(passing.racer_session_id);
        if (!racer)
        {
            console.warn(`Can't locate racer for passing ${JSON.stringify(passing)}`);
            return;
        }
        racer.AddPassing(passing);
        let lap = racer.laps[passing.current_lap];
        if (lap)
        {
            let yellowEnded = this.fcYellow.AddPassing(passing, lap.detectedLapFlag);
            if (yellowEnded)
                this.ReprocessAllRacerPassings();
        }
       
        //console.log(`Added Passing for ${racer.name} lap ${passing.current_lap} @${passing.last_lap_time_seconds}`);
    }

    public ReprocessAllRacerPassings()
    {
        for (let racer of this.racers.values())
            racer.ReprocessPassings();
    }

    public RecalculatePositionsByLap()
    {
        //let classes = alasql("select column distinct [class] from ?", [Array.from(this.racers.values())]);
        this.RecalculatePositionsByLapFor(null);
        for (let byClass of this.classes.values())
            this.RecalculatePositionsByLapFor(byClass);
        
    }

    private RecalculateRelativePositionsFor(byClass: string, pitAdjusted: boolean = false)
    {
        let orderedRacers: Array<Racer>;
        let pos: RelativePosition;
        orderedRacers = this.GetRacersByClass(byClass, pitAdjusted);
        /*if (byClass)
            orderedRacers = <Array<Racer>>alasql("select * from ? where [class]=? order by lapsComplete desc, lastLapCompleteAt asc", [Array.from(this.racers.values()), byClass]);
        else
            orderedRacers = <Array<Racer>>alasql("select * from ? order by lapsComplete desc, lastLapCompleteAt asc", [Array.from(this.racers.values())]);
        */
        for (let i = 0 ; i < orderedRacers.length ; i++)
        {
            pos = (byClass) ? orderedRacers[i].posInClass : orderedRacers[i].posOverall;
            pos.first = orderedRacers[0];
            pos.ahead = (i > 0) ? orderedRacers[i - 1] : null;
            pos.behind = (i < orderedRacers.length - 1) ? orderedRacers[i + 1] : null;
        }
    }

    public GetRacersByClass(byClass: string, pitAdjusted: boolean = false) : Array<Racer>
    {
        let orderedRacers: Array<Racer> = new Array<Racer>();
        for (let racer of this.racers.values())
            if (!byClass || racer.class == byClass)
                orderedRacers.push(racer);
        if (pitAdjusted)
        {
            orderedRacers.sort((a: Racer, b: Racer) => {
               return a.TimeDeltaToAhead(b, true);
            });    
        } else //normal sort order - by laps complete and most recent complete time
        {
            orderedRacers.sort((a: Racer, b: Racer) => {
                    let ret: number = b.lapsComplete - a.lapsComplete;
                    if (ret)
                        return ret;
                    return a.lastLapCompleteAt.getTime() - b.lastLapCompleteAt.getTime();
                });        
        }
        return orderedRacers;
    }

    public RecalculateRelativePositions(pitAdjusted: boolean = false)
    {
        //let classes = alasql("select column distinct [class] from ?", [Array.from(this.racers.values())]);
        this.RecalculateRelativePositionsFor(null, pitAdjusted);
        for (let byClass of this.classes.values())
            this.RecalculateRelativePositionsFor(byClass, pitAdjusted);
    }

    private RecalculatePositionsByLapFor(byClass: string)
    {
        //build a table of all the laps, sort it
        let allLaps = new Array<[Racer, Lap]>();
        for (let racer of this.racers.values())
        {
            if (byClass && racer.class != byClass)
                continue;
            for (let lap of racer.laps)
            {
                if (lap.num > 0 && lap.lapFlag != LapFlag.Unknown && lap.lapFlag != LapFlag.Missing)
                    allLaps.push([racer, lap]);
            }
        }
        allLaps.sort((a:[Racer, Lap], b:[Racer, Lap]):number  => {
                if (a[1].num != b[1].num)
                    return (a[1].num - b[1].num);
                return (a[1].endTime.getTime() - b[1].endTime.getTime());
        });

        //now, update the position on each lap... 
        let lapPosition = new Array<number>();
        for (let racerLap of allLaps)
        {
            if (racerLap[1].num >= lapPosition.length)
                lapPosition[racerLap[1].num] = 0;
            let curPosition = ++lapPosition[racerLap[1].num]; //increment the position counter for this lap
            if (byClass)
                racerLap[1].positionInClass = curPosition;
            else
                racerLap[1].positionOverall = curPosition;
        }

        //console.log(allLaps);



    }
}
